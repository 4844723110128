<template>
  <v-card>
    <v-toolbar dark class="secondary">
      <v-toolbar-title>Sessions</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-select :items="itemDays" hide-details dark label="Last days" v-model="daysSelected" style="max-width: 130px" @change="daySelectedChange"></v-select>
      <v-text-field v-model="search" placeholder="Search" prepend-inner-icon="fa-search" clearable hide-details single-line solo-inverted class="ml-2"></v-text-field>
      <v-tooltip top>
        <v-switch slot="activator" v-model="onlyNeedReview" class="pl-2 pt-4" @change="daySelectedChange"></v-switch>
        <span>Show only sessions that need review</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="pa-1">
      <v-progress-linear style="position: absolute" v-show="loading" :indeterminate="true" class="ma-0"></v-progress-linear>
      <v-layout row wrap v-if="itemsStatuses.length > 0">
        <v-flex xs12 md3>
          <v-list dense>
            <template v-for="(item, index) in itemsStatuses">
              <v-list-tile
                :key="item.sessionStatus"
                avatar
                @click="selectStatus(item.sessionStatus)"
                :to="item.sessionStatus"
                :class="item.sessionStatus === statusSelected ? 'blue-grey white--text' : ''"
              >
                <v-list-tile-content>
                  <v-list-tile-title v-html="item.sessionStatus"></v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-action>
                  <v-list-tile-action-text>
                    <v-chip :color="item.sessionStatusColor" text-color="white" small>{{ item.count }}</v-chip>
                  </v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider v-if="index + 1 < itemsStatuses.length" :key="`divider-${index}`"></v-divider>
            </template>
          </v-list>
        </v-flex>
        <v-flex xs12 md9>
          <session-list-table :search="search" :items="sessions" :loading="loading"></session-list-table>
        </v-flex>
      </v-layout>
      <v-layout v-else row wrap>
        <v-flex xs12>
          <v-alert :value="true" type="warning"> No sessions with the criteria selected </v-alert>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import userApi from "@/services/api/UserServices";
import sessionListTable from "@/components/sessions/SessionListTable";

export default {
  props: {
    days: {
      type: Number,
      required: false,
      default: 30
    },
    showOpen: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  components: {
    sessionListTable
  },

  data() {
    return {
      loading: false,
      search: "",
      statusSelected: "",
      itemDays: [7, 15, 30, 60, 90, 180, 365],
      daysSelected: this.days,
      itemsStatuses: [],
      sessions: [],
      onlyNeedReview: false
    };
  },

  mounted() {
    this.getSessionsStatuses();
  },

  methods: {
    async getSessionsStatuses() {
      this.loading = true;
      try {
        this.itemsStatuses = await userApi.getSessionListOnlyStatus(this.daysSelected, true, this.onlyNeedReview);
        if (this.itemsStatuses.length > 0) {
          this.statusSelected = this.itemsStatuses[0].sessionStatus;
          await this.selectStatus(this.statusSelected);
        }
      } catch (error) {
        console.error(error);
        this.$toast.error(error);
      } finally {
        this.loading = false;
      }
    },

    async daySelectedChange() {
      this.getSessionsStatuses();
    },

    async selectStatus(newStatus) {
      this.statusSelected = newStatus;
      this.loading = true;
      try {
        let sessionsLocal = [];
        const sessions = await userApi.getSessionListByStatus(this.statusSelected, this.daysSelected, true, this.onlyNeedReview);
        sessions.forEach((e) => {
          e.sessionStart = this.$moment(e.sessionStart).local();
          e.sessionEnd = this.$moment(e.sessionEnd).local();
          sessionsLocal.push(e);
        });
        this.sessions = Object.freeze(sessionsLocal);
      } catch (error) {
        console.error(error);
        this.$toast.error(error);
      } finally {
        this.loading = false;
      }
    },

    sessionNotes(session) {
      this.$store.commit("SET_ACTIVE_DATE", session.sessionStart);
      this.$store.commit("SET_ACTIVE_CLIENT", session.clientId);
      this.$store.commit("SET_ACTIVE_SESSION", session.sessionId);
      this.$router.push("/clients/session_notes");
    },

    sessionData(session) {
      this.$store.commit("SET_ACTIVE_DATE", session.sessionStart);
      this.$store.commit("SET_ACTIVE_CLIENT", session.clientId);
      this.$store.commit("SET_ACTIVE_SESSION", session.sessionId);
      this.$router.push("/session/session_collect_data");
    }
  }
};
</script>

<style scoped></style>
